import { Link } from "gatsby"
import React, { useCallback, useState } from "react"

import MenuIcon from "../images/icons/menu.svg"

const NAV_LINKS = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "About me",
    link: "/about",
  },
  {
    title: "Teaching statement",
    link: "/statement",
  },
  {
    title: "Tuition",
    link: "/tuition",
  },
  {
    title: "Gallery",
    link: "/gallery",
  },
  {
    title: "Contact",
    link: "/contact",
  },
]

const Header = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  const handleNavbarToggle = useCallback(() => {
    setNavbarOpen(!navbarOpen)
  }, [navbarOpen])

  return (
    <header className="bg-red border-b-5 border-red-dark fixed top-0 left-0 w-full z-30 px-4">
      <nav className="flex flex-wrap items-center justify-end md:justify-center">
        <button
          type="button"
          className="block md:hidden text-white focus:outline-none p-4"
          onClick={handleNavbarToggle}
        >
          <MenuIcon className="fill-current" />
        </button>
        <ul
          className={`${
            navbarOpen ? "flex" : "hidden md:flex"
          } flex-wrap justify-center w-full`}
        >
          {NAV_LINKS.map((link, index) => (
            <li key={`nav-item-${index}`}>
              <Link
                to={link.link}
                className="text-white hover:text-gray-dark font-semibold whitespace-no-wrap inline-block px-4 py-5"
              >
                {link.title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  )
}

export default Header
